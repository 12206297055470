import React from "react";
import { Link } from "gatsby";
import { ExtendedHeader, EnvironmentContext } from "~components/header";
import Footer from "~components/footer";
import { HomeTitle } from "~components/text-helpers";
import { FeatureCard } from "~components//cards";
import { css } from "@emotion/react";
import iTHOR_LOGO from "~icons/ithor-white.svg";
import color from "~styles/color";
import SiteHelmet from "~components/site-helmet";

// GIFs!
import microwaveGIF from "~media/ithor-microwave.gif";
import multiAgentGIF from "~media/ithor-multi-agent.gif";
import plateGIF from "~media/ithor-plate.gif";
import basketballGIF from "~media/ithor-basketball.gif";

export default function Home() {
  return (
    <>
      <SiteHelmet>
        <title>iTHOR</title>
      </SiteHelmet>
      <ExtendedHeader
        videoLink="https://player.vimeo.com/video/509322791?autoplay=1&loop=1&muted=1"
        upperContent={
          <EnvironmentContext
            logo={iTHOR_LOGO}
            description="
              iTHOR is an environment within the AI2-THOR framework, which
              includes a set of interactive objects and scenes and provides
              accurate modeling of the physics of the world.
          "
          />
        }
      />
      <div
        css={css`
          text-align: center;
          padding-bottom: 80px;
          padding-top: 30px;
        `}
      >
        <HomeTitle title="Key Features" />
        <FeatureCard
          title="Physics"
          description="iTHOR is built upon the Unity physics engine and it models physical properties for objects and scenes: mass, friction, bouncing, etc."
          gif={basketballGIF}
        />
        <FeatureCard
          title="Object Manipulation"
          description="iTHOR provides object manipulation actions: pick up objects, drop objects, push objects, etc."
          gif={plateGIF}
        />
        <FeatureCard
          title="State Changes"
          description="iTHOR enables changing the state of objects, for example, open/close microwave, cook food, switch on/off lights."
          gif={microwaveGIF}
        />
        <FeatureCard
          title="Multi-Agent"
          description="iTHOR enables performing tasks with multiple agents of different types."
          gif={multiAgentGIF}
        />
      </div>
      <Footer />
    </>
  );
}
